@media only screen and (orientation: portrait) {
  .Small-app {
    text-align: center;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
  }

  .Small-top {
    max-width: 100vw;
    max-height: 100vh;
    margin: auto;
    position: relative;
  }

  .Small-profile {
    width: 100%;
  }

  .Small-insta {
    height: calc(16vw + 5vh);
    position: absolute;
    top: 1%;
    right: 1%;
  }

  .Small-smr {
    height: calc(16vw + 5vh);
    position: absolute;
    top: 1%;
    left: 1%;
  }

  .Small-title {
    font-size: calc(5vw + 5vh);
    position: absolute;
    bottom: 0;
    left: 0;
    margin: 0;
  }

  .Small-bio {
    font-size: calc(3vw + 1vh);
    text-align: left;
    margin-top: 1.8%;
    margin-left: 3%;
    margin-right: 3%;
    margin-bottom: 0;
    text-indent: 10vw;
  }

  .Small-music {
    font-size: calc(5vw + 5vh);
    margin-top: 1%;
    margin-bottom: 3%;
  }

  .Small-songs {
    min-width: 280px;
    max-width: 349px;
    height: 160px;
    width: 99%;
    margin-bottom: 10px;
  }

  .Small-performances {
    font-size: calc(3vw + 5vh);
    margin-top: 0;
    margin-bottom: 1%;
  }

  .Small-venue {
    font-size: calc(1vw + 2.1vh);
    margin-bottom: 1%;
  }

  .Small-contact {
    font-size: calc(5vw + 3vh);
    color: white;
    margin-top: 1%;
    margin-bottom: 3%;
  }

  .Small-email {
    font-size: calc(3vw + 1vh);
    color: white;
    margin-bottom: 3%;
  }

  .Small-phone {
    font-size: calc(3vw + 1vh);
    color: white;
    margin-bottom: 5%;
  }
}

@media only screen and (orientation: landscape) {
  .Small-app {
    text-align: center;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
  }

  .Small-top {
    max-width: 100vw;
    max-height: 100vh;
    margin: auto;
    position: relative;
  }

  .Small-profile {
    width: 100%;
  }

  .Small-insta {
    height: calc(16vw + 5vh);
    position: absolute;
    top: 1%;
    right: 1%;
  }

  .Small-smr {
    height: calc(16vw + 5vh);
    position: absolute;
    top: 1%;
    left: 1%;
  }

  .Small-title {
    font-size: calc(5vw + 5vh);
    position: absolute;
    bottom: 0;
    left: 0;
    margin: 0;
  }

  .Small-bio {
    font-size: calc(3vw + 1vh);
    text-align: left;
    margin-top: 1.8%;
    margin-left: 3%;
    margin-right: 3%;
    margin-bottom: 0;
    text-indent: 10vw;
  }

  .Small-music {
    font-size: calc(5vw + 5vh);
    margin-top: 1%;
    margin-bottom: 3%;
  }

  .Small-songs {
    min-width: 280px;
    max-width: 349px;
    height: 160px;
    width: 99%;
    margin-bottom: 10px;
  }

  .Small-performances {
    font-size: calc(3vw + 5vh);
    margin-top: 0;
    margin-bottom: 1%;
  }

  .Small-venue {
    font-size: calc(1vw + 2.1vh);
    margin-bottom: 1%;
  }

  .Small-contact {
    font-size: calc(5vw + 3vh);
    color: white;
    margin-top: 1%;
    margin-bottom: 3%;
  }

  .Small-email {
    font-size: calc(3vw + 1vh);
    color: white;
    margin-bottom: 3%;
  }

  .Small-phone {
    font-size: calc(3vw + 1vh);
    color: white;
    margin-bottom: 5%;
  }
}
