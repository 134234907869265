.Landing-app {
  text-align: center;
  min-height: 100vh;
  width: 100vw;
  align-items: center;
  color: white;
}

.Landing-learn {
  font-size: calc(3vw + 1vh);
  color: white;
  position: absolute;
  text-decoration: none;
  top: 1%;
  left: 1%;
}

.Landing-image {
  margin-top: 7vh;
  min-height: 180px;
  height: 30%;
  max-height: 250px;
  pointer-events: none;
  margin-bottom: 1.8vh;
}

@media (prefers-reduced-motion: no-preference) {
  .Landing-image {
    animation: Landing-image-spin infinite 21s linear;
  }
}

@keyframes Landing-image-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Landing-smaller {
  margin-bottom: 1.2vh;
  height: 160px;
  min-width: 280px;
  max-width: 349px;
  width: 99%;
}

.Landing-track {
  margin-bottom: 1.2vh;
  height: 160px;
  min-width: 550px;
  max-width: 570px;
  width: 75%;
}

.Landing-collection {
  font-size: calc(3vw + 1vh);
}

.Landing-input {
  margin-top: 1.8vh;
  margin-bottom: 1.8vh;
  font-size: calc(3vw + 1vh);
  border-radius: 25px;
  min-width: 250px;
  max-width: 570px;
  width: 64%;
  text-align: center;
}

.Landing-confirm {
  margin-bottom: 1.8vh;
  font-size: calc(3vw + 1vh);
  border-radius: 25px;
  min-width: 210px;
  max-width: 500px;
  width: 52%;
  /* text-align: center; */
}

.Landing-gracias {
  font-size: calc(3vw + 1vh);
}
