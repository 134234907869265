@media only screen and (orientation: portrait) {
  .Desktop-app {
    width: 100vw;
    color: white;
  }

  .Desktop-top {
    display: flex;
    justify-content: space-between;
  }

  .Desktop-profile {
    width: 50vw;
    min-height: 100px;
  }

  .Desktop-album {
    width: 50vw;
    min-height: 100px;
  }

  .Desktop-first {
    font-size: calc(5vw + 3vh);
    position: absolute;
    right: 52vw;
    top: 0;
    margin: 0;
  }

  .Desktop-surname {
    font-size: calc(5vw + 3vh);
    position: absolute;
    left: 50vw;
    top: 0;
    margin: 0;
  }

  .Desktop-topline {
    position: absolute;
    width: 100vw;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
  }

  .Desktop-image {
    height: calc(7vw + 5vh);
  }

  .Desktop-bio {
    text-align: center;
    font-size: calc(5vw + 3vh);
    margin-top: 3%;
    margin-bottom: 0%;
  }

  .Desktop-words {
    font-size: calc(1.8vw + 1vh);
    text-indent: calc(5vw + 3vh);
    margin: 3%;
  }

  .Desktop-music {
    text-align: center;
    font-size: calc(5vw + 3vh);
    margin-top: 0;
    margin-bottom: 3%;
  }

  .Desktop-row {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 10px;
  }

  .Desktop-songs {
    min-width: 320px;
    /* max-width: 500px; */
    height: 160px;
    width: 48%;
  }

  .Desktop-works {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .Desktop-track {
    min-width: 500px;
    max-width: 700px;
    height: 160px;
    width: 99%;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 3%;
  }

  .Desktop-performances {
    text-align: center;
    font-size: calc(7vw + 5vh);
    margin-top: 0;
    margin-bottom: 3%;
  }

  .Desktop-box {
    display: flex;
    justify-content: center;
    margin-bottom: 1%;
  }

  .Desktop-stack {
    display: flex;
    flex-direction: column;
    text-align: right;
    margin-right: 1%;
  }

  .Desktop-venue {
    font-size: calc(1.6vw + 1vh);
    margin-bottom: 3%;
  }

  .Desktop-right {
    display: flex;
    flex-direction: column;
    margin-left: 1%;
  }

  .Desktop-contact {
    text-align: center;
    font-size: calc(7vw + 3vh);
    margin-top: 0;
    margin-bottom: 3%;
  }

  .Desktop-holding {
    text-align: center;
    display: flex;
    flex-direction: column;
  }

  .Desktop-email {
    font-size: calc(2.1vw + 1vh);
    color: white;
    margin-bottom: 3%;
  }

  .Desktop-phone {
    font-size: calc(2.1vw + 1vh);
    color: white;
    margin-bottom: 3%;
  }
}

@media only screen and (orientation: landscape) {
  .Desktop-app {
    width: 100vw;
    color: white;
  }

  .Desktop-top {
    display: flex;
    justify-content: space-between;
  }

  .Desktop-profile {
    width: 50vw;
    min-height: 100px;
  }

  .Desktop-album {
    width: 50vw;
    min-height: 100px;
  }

  .Desktop-first {
    font-size: calc(5vw + 5vh);
    position: absolute;
    right: 52vw;
    top: 0;
    margin: 0;
  }

  .Desktop-surname {
    font-size: calc(5vw + 5vh);
    position: absolute;
    left: 50vw;
    top: 0;
    margin: 0;
  }

  .Desktop-topline {
    position: absolute;
    width: 100vw;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
  }

  .Desktop-image {
    height: calc(10vw + 10vh);
  }

  .Desktop-bio {
    text-align: center;
    font-size: calc(5vw + 3vh);
    margin-top: 3%;
    margin-bottom: 0%;
  }

  .Desktop-words {
    font-size: calc(1.8vw + 1vh);
    text-indent: calc(5vw + 3vh);
    margin: 3%;
  }

  .Desktop-music {
    text-align: center;
    font-size: calc(5vw + 3vh);
    margin-top: 0;
    margin-bottom: 3%;
  }

  .Desktop-row {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 10px;
  }

  .Desktop-songs {
    min-width: 320px;
    /* max-width: 500px; */
    height: 160px;
    width: 48%;
  }

  .Desktop-works {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .Desktop-track {
    min-width: 500px;
    max-width: 545px;
    height: 160px;
    width: 99%;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 3%;
  }

  .Desktop-performances {
    text-align: center;
    font-size: calc(7vw + 5vh);
    margin-top: 0;
    margin-bottom: 3%;
  }

  .Desktop-box {
    display: flex;
    justify-content: center;
    margin-bottom: 1%;
  }

  .Desktop-stack {
    display: flex;
    flex-direction: column;
    text-align: right;
    margin-right: 1%;
  }

  .Desktop-venue {
    font-size: calc(1.6vw + 1vh);
    margin-bottom: 3%;
  }

  .Desktop-right {
    display: flex;
    flex-direction: column;
    margin-left: 1%;
  }

  .Desktop-contact {
    text-align: center;
    font-size: calc(7vw + 3vh);
    margin-top: 0;
    margin-bottom: 3%;
  }

  .Desktop-holding {
    text-align: center;
    display: flex;
    flex-direction: column;
  }

  .Desktop-email {
    font-size: calc(2.1vw + 1vh);
    color: white;
    margin-bottom: 3%;
  }

  .Desktop-phone {
    font-size: calc(2.1vw + 1vh);
    color: white;
    margin-bottom: 3%;
  }
}
